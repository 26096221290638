@import "~antd/dist/antd.css";
@import "~antd-button-color/dist/css/style.css";
body {
  margin: 0;
  font-family: "PingFangSC-Regular",-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: currentColor !important;
}

.ant-carousel .slick-prev::before {
  color: currentColor !important;
}

.slick-arrow.slick-prev {
  font-size: 35px !important;
  left:10px;
  z-index:1000;
}

.ant-carousel .slick-next::before {
  color: currentColor !important;
}

.slick-arrow.slick-next {
  font-size: 35px !important;
  right:20px;
  z-index:1000;
}